<template>
    <MainHeader :sidebar="$route.meta.layout == 'default' ? true : false" v-if="
        $route.meta.layout == 'with-header' ||
        $route.meta.layout == 'default'
    " :showNotificationBar="isShowNotificationBar" />

    <div id="time-line-main" :class="{
        'p-0':
            $route.meta.layout !== 'with-header' &&
            $route.meta.layout !== 'default',
    }">
        <div class="main-page hide-scrollbar" :class="{
            'has-sidebar': $route.meta.layout == 'default',
            'H-100 border-radius-0': $route.meta.layout == 'full',
        }">
            <div class="main-menu" v-if="$route.meta.layout == 'default'">

                <MainMenu :servicesArray="services" :selectedProjectId="selectedProjectId"
                    :selectedProjectLogo="selectedProjectLogo" :selectedServiceId="selectedServiceId" />
            </div>
            <div class="router-outlet">
                <router-view></router-view>
            </div>

            <Notifications :accessToken="accessToken" @toggleNotificationBar="toggleNotificationBar"
                @getProjectsData="getProjects" />
        </div>
    </div>

    <OverlayLoader v-show="isLoading" />
</template>

<script>
import OverlayLoader from '@/components/base/overlay/OverlayLoader.vue';
import MainHeader from '@/components/base/MainHeader';
import MainMenu from '@/components/base/MainMenu';
import Notifications from './components/base/Notifications.vue';

import store from '@/store';

import { projects } from '@/services/axios/projects.service';

export default {
    name: 'App',
    components: {
        OverlayLoader,
        MainHeader,
        MainMenu,
        Notifications,
    },

    data() {
        return {
            isShowNotificationBar: false,
        };
    },

    computed: {
        isLoading: {
            get() {
                return this.$store.state.isLoading;
            },
        },
        services: {
            get() {
                return this.$store.state.services;
            },
        },
        selectedProjectId: {
            get() {
                return this.$store.state.selectedProjectId;
            },
        },
        selectedProjectLogo: {
            get() {
                return this.$store.state.selectedProjectLogo;
            },
        },
        selectedServiceId: {
            get() {
                return this.$store.state.selectedServiceId;
            },
        },
        accessToken: {
            get() {
                return this.$store.state.accessToken;
            },
        },
    },

    mounted() {
        if (window.innerWidth <= 768) {
            document.getElementsByTagName('html')[0].style.height =
                window.visualViewport.height.toString() + 'px';
            document.getElementById('time-line-main').style.height =
                `${window.visualViewport.height}px`;
        }
    },

    methods: {
        toggleNotificationBar(isShow) {
            this.isShowNotificationBar = isShow;
        },

        projects,
        async getProjects(value, callback) {
            const res = await this.projects();
            const projectsArray = res.data.result;
            store.commit('mutationer', { services: projectsArray });
            localStorage.setItem(
                'services',
                JSON.stringify(projectsArray)
            );

            callback();
        },
    },
};
</script>
