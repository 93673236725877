import httpClient from './notificationIndex';

const END_POINT = 'notification';

const getNotifications = () =>
    httpClient.get(END_POINT, {
        signal: AbortSignal.timeout(2500),
    });

export { getNotifications };
