import Vuex from 'vuex';

import App from '../App.vue';
import { createApp } from 'vue';

const app = createApp(App);

app.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoading: '',
        accessToken: '',
        companyLogo: '',
        companyTitle: '',
        avatar: '',
        username: '',
        selectedProjectId: '',
        selectedServiceLogo: '',
        selectedServiceId: '',
        selectedServiceReviewStudio: '',
        services: [],
    },
    mutations: {
        mutationer(state, payload) {
            let keys = Object.keys(payload);
            for (let item of keys) {
                state[item] = payload[item];
            }
        },
    },
});
