import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { toast } from 'vue3-toastify';

const toastConfig = {
    autoClose: 3000,
    position: toast.POSITION.BOTTOM_LEFT,
    type: toast.TYPE.ERROR,
    theme: toast.THEME.COLORED,
};

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});

// eslint-disable-next-line no-unused-vars
let numberOfAjaxCAllPending = 0;

httpClient.interceptors.request.use(
    config => {
        const token =
            store.state.accessToken || localStorage.getItem('accessToken');
        token ? (config.headers.Authorization = 'Bearer' + ' ' + token) : '';

        numberOfAjaxCAllPending++;
        store.commit('mutationer', { isLoading: true });
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

httpClient.interceptors.response.use(
    response => {
        numberOfAjaxCAllPending--;

        if (numberOfAjaxCAllPending == 0)
            store.commit('mutationer', { isLoading: false });
        return response;
    },
    error => {
        numberOfAjaxCAllPending--;

        store.commit('mutationer', { isLoading: false });
        Promise.reject(error);

        toast(
            error?.response?.data?.message ||
                error?.response?.data?.result?.message ||
                error?.response?.data?.data?.message,
            toastConfig
        );

        if (error?.response?.status === 401) {
            localStorage.removeItem('accessToken');
            store.commit('mutationer', { accessToken: '' });
            localStorage.clear();
            document
                .getElementById('main-notifications')
                .classList.remove('open');

            router.push({ name: 'login' });
        }
    }
);

export default httpClient;
