import App from './App.vue';
import router from '@/router';
import { createApp } from 'vue';
import mitt from 'mitt';
import store from '@/store';
import { registerLicense } from '@syncfusion/ej2-base';

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(router);
app.use(store);
registerLicense(
    'Ngo9BigBOggjHTQxAR8/V1NAaF1cXmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjW35dcXZVTmJYV0BzXw=='
);

app.mount('#app');
