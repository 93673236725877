<template>
    <header class="main-header">
        <div class="row">
            <div class="col-6">
                <div class="header-left">
                    <a
                        @click="toggleSidebar()"
                        class="d-xl-none d-flex mr-4 sidebar-btn cursor-pointer"
                        v-if="sidebar">
                        <img
                            v-if="!isShowSidebar"
                            src="@/assets/icons/svg/menu.svg"
                            alt="brand"
                            width="40"
                            height="40" />
                        <img
                            v-else
                            src="@/assets/icons/svg/close.svg"
                            alt="brand"
                            width="20"
                            height="20" />
                    </a>

                    <router-link
                        to="/"
                        class="brand">
                        <img
                            src="@/assets/icons/svg/qoo-white-logo.svg"
                            alt="top-icon" />
                    </router-link>

                    <router-link
                        to="/"
                        class="home-link"
                        v-if="$route.meta.title == 'Profile'">
                        <img
                            src="@/assets/icons/svg/Iconly-Light-Home.svg"
                            alt="top-icon" />
                    </router-link>
                </div>
            </div>
            <div class="col-6">
                <div class="header-right">
                    <a
                        id="toggle-notif"
                        class="notification cursor-pointer"
                        @click="toggleNotificationBar()"
                        :class="{
                            'open-notifications': isShowNotificaionBar,
                            'has-notification': hasNotification,
                        }">
                        <img
                            src="@/assets/icons/svg/Iconly-Light-outline-Notification.svg"
                            alt="top-icon"
                            v-if="!isShowNotificaionBar" />
                        <img
                            src="@/assets/icons/svg/close.svg"
                            alt="brand"
                            width="17"
                            v-else />
                    </a>
                    <a
                        class="user cursor-pointer"
                        @click="toggleUserDetail()"
                        :class="{ active: isShowUserDetail }">
                        <img
                            :src="
                                avatar
                                    ? avatar
                                    : localStorageAvatar
                                      ? localStorageAvatar
                                      : require('@/assets/images/user-default.png')
                            "
                            alt="top-icon" />

                        <div class="user-detail">
                            <div class="top">
                                <p>{{ username }}</p>
                                <span>{{ companyName }}</span>
                            </div>
                            <ul>
                                <li>
                                    <router-link to="profile">
                                        <img
                                            src="@/assets/icons/svg/vuesax-outline-user.svg"
                                            alt="icon" />
                                        View Profile
                                    </router-link>
                                </li>
                                <li @click="logout()">
                                    <a class="cursor-pointer">
                                        <img
                                            src="@/assets/icons/svg/vuesax-outline-logout.svg"
                                            alt="icon" />
                                        Log out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
    </header>
</template>

<script>
import store from '@/store';

import { logout } from '@/services/axios/logout.service';

import { toast } from 'vue3-toastify';
import ConfirmDialogue from '@/components/base/ConfirmDialogue.vue';

export default {
    name: 'MainHeader',
    components: {
        ConfirmDialogue,
    },
    props: {
        sidebar: Boolean,
        showNotificationBar: Boolean,
    },

    data() {
        return {
            toastConfig: {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_LEFT,
                type: toast.TYPE.SUCCESS,
                theme: toast.THEME.COLORED,
            },

            isShowSidebar: false,
            isShowNotificaionBar: false,
            isShowUserDetail: false,

            username: store.state.username || localStorage.getItem('username'),
            companyName:
                store.state.companyTitle ||
                localStorage.getItem('companyTitle'),
            localStorageAvatar: localStorage.getItem('avatar'),
        };
    },

    methods: {
        async logout() {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Logout User',
                message: 'Are you sure?',
                okButton: 'Yes!',
            });
            if (ok) {
                this.userLogout();
            }
        },

        userLogout() {
            logout()
                .then(res => {
                    if (!res) {
                        return;
                    }

                    localStorage.clear();
                    document
                        .getElementById('main-notifications')
                        .classList.remove('open');
                    this.isShowNotificaionBar = false;
                    store.commit('mutationer', { accessToken: '' });

                    this.$router.push({ name: 'login' });

                    // toast(res?.data?.message, this.toastConfig);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        toggleSidebar() {
            const sidenav = document.getElementById('main-sidebar');
            const main = document.getElementById('time-line-main');

            sidenav.classList.toggle('open');
            main.classList.toggle('open-sidebar');

            this.isShowSidebar = !this.isShowSidebar;
        },

        toggleNotificationBar() {
            const notif = document.getElementById('main-notifications');
            notif.classList.toggle('open');

            this.isShowNotificaionBar = !this.isShowNotificaionBar;
        },

        toggleUserDetail() {
            this.isShowUserDetail = !this.isShowUserDetail;
        },
    },

    mounted() {
        const mainPanelDiv = document.querySelector('#time-line-main');
        const notif = document.getElementById('main-notifications');

        mainPanelDiv.addEventListener(
            'click',
            function (event) {
                const target = event.target;
                if (
                    target.parentElement?.classList[0] !=
                        'main-notifications' &&
                    target.parentElement?.parentElement?.classList[0] !=
                        'main-notifications' &&
                    target.parentElement?.parentElement?.parentElement
                        ?.classList[0] != 'main-notifications' &&
                    target.parentElement?.parentElement?.parentElement
                        ?.parentElement?.classList[0] != 'main-notifications'
                ) {
                    notif.classList.remove('open');
                    this.isShowNotificaionBar = false;
                }
            }.bind(this)
        );
    },

    computed: {
        hasNotification: {
            get() {
                return this.$store.state.hasNotification;
            },
        },
        avatar: {
            get() {
                return this.$store.state.avatar;
            },
        },

        isShowNotificationBar: {
            get() {
                return this.showNotificationBar;
            },
        },
    },

    watch: {
        $route(to) {
            console.log(to);
            if (
                to.path == '/' ||
                to.path == '/provided-services' ||
                to.path == '/view-steps'
            ) {
                const sidenav = document.getElementById('main-sidebar');
                const main = document.getElementById('time-line-main');

                if (sidenav) {
                    sidenav.classList.remove('open');
                    main.classList.remove('open-sidebar');

                    this.isShowSidebar = false;
                }
            }
        },

        isShowNotificationBar() {
            const notif = document.getElementById('main-notifications');
            notif.classList.remove('open');
            this.isShowNotificaionBar = false;
        },
    },
};
</script>

<style lang="scss">
@import 'MainHeader.scss';
</style>
