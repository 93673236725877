<template>
    <div
        class="main-menu"
        id="main-sidebar">
        <div class="back-to-home">
            <router-link to="/">
                <img
                    src="@/assets/icons/svg/vuesax-outline-arrow-right.svg"
                    alt="arrow"
                    class="arrow-left" />
                Back to Home
            </router-link>
        </div>

        <div class="tabs">
            <div
                class="tab"
                v-for="(item, index) in projectsWithServices"
                :key="index">
                <input
                    type="radio"
                    name="p-sidenav"
                    :id="'link-' + item.id"
                    :checked="item.id == projectId"
                    @change="goToServices(item.id, item.logo)" />
                <label
                    class="tab-label"
                    :for="'link-' + item.id"
                    @click="checkRoute(item.id, item.logo)">
                    {{ item.name }}
                    <img
                        src="@/assets/icons/png/down-arrow.png"
                        alt="arrow" />
                </label>
                <div class="tab-content">
                    <ul v-if="item.services.length">
                        <li
                            v-for="(service, index) in item.services"
                            :key="index"
                            @click="
                                goToSteps(service.id, service.review_studio)
                            "
                            :class="{ active: service.id == serviceId }">
                            {{ service.service?.name }}
                        </li>
                    </ul>
                    <p v-else>no services found!</p>
                </div>
            </div>

            <div
                class="project-logo"
                v-if="projectLogo && projectLogo != 'null'">
                <img
                    :src="projectLogo"
                    alt="logo" />
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';

export default {
    name: 'MainMenu',
    props: {
        servicesArray: Array,
        selectedProjectId: String,
        selectedProjectLogo: String,
        selectedServiceId: String,
    },

    data() {
        return {
            projectsWithServices: this.servicesArray.length
                ? this.servicesArray
                : JSON.parse(localStorage.getItem('services')),
            projectId:
                this.selectedProjectId ||
                JSON.parse(localStorage.getItem('selectedProjectId')),

            projectLogo:
                this.selectedProjectLogo ||
                localStorage.getItem('selectedProjectLogo'),

            serviceId:
                this.selectedServiceId ||
                JSON.parse(localStorage.getItem('selectedServiceId')),
        };
    },

    watch: {
        selectedServiceId: function (value) {
            this.serviceId = value;
        },
        selectedProjectLogo: function (value) {
            this.projectLogo = value;
        },
        selectedProjectId: function (value) {
            this.projectId = value;
        },
        servicesArray: function(value) {
            this.projectsWithServices = value;
        },
    },

    methods: {
        goToServices(projectId, projectLogo) {
            store.commit('mutationer', { selectedProjectId: projectId });
            localStorage.setItem('selectedProjectId', projectId);

            store.commit('mutationer', { selectedProjectLogo: projectLogo });
            localStorage.setItem('selectedProjectLogo', projectLogo);

            this.$router.push('/provided-services' + '?id=' + projectId);
        },

        checkRoute(projectId, projectLogo) {
            if (this.$route.name === 'viewSteps') {
                this.goToServices(projectId, projectLogo);
            }
        },

        goToSteps(serviceId, serviceReviewStudio) {
            store.commit('mutationer', { selectedServiceId: serviceId });
            localStorage.setItem('selectedServiceId', serviceId);

            if (serviceReviewStudio) {
                store.commit('mutationer', {
                    selectedServiceReviewStudio: serviceReviewStudio,
                });
                localStorage.setItem(
                    'selectedServiceReviewStudio',
                    serviceReviewStudio
                );
            } else {
                localStorage.removeItem('selectedServiceReviewStudio');
                store.commit('mutationer', { selectedServiceReviewStudio: '' });
            }

            this.$router.push('/view-steps' + '?serviceId=' + serviceId);
        },
    },
};
</script>
